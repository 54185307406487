import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import moment from 'moment'
import { GoGitPullRequest } from 'react-icons/go'
import {
  MdSchedule,
  MdToday,
  MdStar,
  MdLinearScale,
  MdCallSplit,
  MdInfoOutline,
  MdArrowForward,
} from 'react-icons/md'
import Layout from '../components/Layout'
import * as Hero from '../components/Hero'
import Centered from '../components/Centered'
import RichText from '../components/RichText'

import digiaLogo from '../../assets/images/digia-logo.png'

import styles from './ProjectPage.module.css'

const pluralize = require('pluralize')

export const query = graphql`
  query ProjectPage($slug: String!) {
    project(fields: { slug: { eq: $slug } }) {
      title
      description
      body
      fields {
        slug
      }
      links {
        label
        url
      }
      repository {
        name
        primaryLanguage
        url
        commits
        forks
        issues
        pullRequests
        stargazers
        watchers
        createdAt
        pushedAt
      }
    }
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
        githubUrl
      }
    }
  }
`

const ProjectPage = ({ data: { project, site: { siteMetadata } } }) => (
  <Layout>
    <div className={styles.component}>
      <Helmet
        title={project.title + ' · ' + siteMetadata.title}
        meta={[
          { name: 'description', content: project.description },
          { name: 'theme-color', content: '#ac0d16' },
          { property: 'og:title', content: project.title },
          { property: 'og:type', content: 'article' },
          {
            property: 'og:url',
            content: siteMetadata.siteUrl + project.fields.slug,
          },
          { property: 'og:description', content: project.description },
          { property: 'og:image', content: siteMetadata.siteUrl + digiaLogo },
          { property: 'og:image:width', content: '1200' },
          { property: 'og:image:height', content: '990' },
        ]}
        link={[
          { rel: 'canonical', href: siteMetadata.githubUrl + project.fields.slug },
        ]}
      />
      <Hero.Box className={styles.heroBox}>
        <Hero.Back />
        <Hero.Title>{project.title}</Hero.Title>
        <Hero.Lead className={styles.heroLead}>{project.description}</Hero.Lead>
      </Hero.Box>
      <Centered>
        <div className={styles.content}>
          <div className={styles.main}>
            <RichText content={project.body} />
            {project.links && project.links.length ? (
              <div className={styles.related}>
                <h2 className={styles.relatedTitle}>Related links</h2>
                <ul className={styles.relatedLinks}>
                  {project.links.map(link => (
                    <li>
                      <a href={link.url} target="_blank" rel="nofollow noopener noreferrer">
                        {link.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
          <div className={styles.aside}>
            <div className={styles.info}>
              <div className={styles.infoBox}>
                <ul className={styles.infoStats}>
                  <li>
                    <MdSchedule className={styles.infoStatsIcon} /> Created{' '}
                    {moment(project.repository.createdAt).format('MMM Do, YYYY')}
                  </li>
                  <li>
                    <MdToday className={styles.infoStatsIcon} /> Updated{' '}
                    {moment(project.repository.pushedAt).from()}
                  </li>
                  <li>
                    <MdLinearScale className={styles.infoStatsIcon} />{' '}
                    {pluralize('commit', project.repository.commits, true)}
                  </li>
                  <li>
                    <MdStar className={styles.infoStatsIcon} />{' '}
                    {pluralize('star', project.repository.stargazers, true)}
                  </li>
                  <li>
                    <MdCallSplit className={styles.infoStatsIcon} />{' '}
                    {pluralize('fork', project.repository.forks, true)}
                  </li>
                  <li>
                    <MdInfoOutline className={styles.infoStatsIcon} />{' '}
                    {pluralize('open issue', project.repository.issues, true)}
                  </li>
                  <li>
                    <GoGitPullRequest className={styles.infoStatsIcon} />{' '}
                    {pluralize('pull request', project.repository.pullRequests, true)}
                  </li>
                </ul>
              </div>
              <div className={styles.infoGithub}>
                <a
                  className={styles.infoGithubLink}
                  href={project.repository.url}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  View on GitHub{' '}
                  <MdArrowForward className={styles.infoGithubIcon} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </Centered>
    </div>
  </Layout>
)

export default ProjectPage
